import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import SplashView from "@/views/SplashView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect() {
      return {
        name: "error",
        params: {
          code: 400,
        },
      };
    },
  },
  {
    path: "/thank-you",
    name: "thank-you",
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/ThankYouView.vue"),
    props: true,
  },

  //
  {
    path: "/s/:sid",
    name: "short-link",
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/SplashNewView.vue"),
    props: true,
  },

  // organization Router
  {
    path: "/o/:orgId/thank-you",
    name: "organization.thank-you",
    component: () =>
      import(
        /* webpackChunkName: "user" */ "@/views/organization/ThankYouView.vue"
      ),
    props: true,
  },
  {
    path: "/:identifier",
    name: "user.home",
    component: SplashView,
    props: true,
  },

  // V2
  {
    path: "/v2/:identifier",
    name: "v2.landing",
    component: () =>
      import(/* webpackChunkName: "v2" */ "@/views/V2/BaseV2.vue"),
    props: true,
    children: [
      // {
      //   path: "select-banks",
      //   name: "v2.select-banks",
      //   component: () =>
      //     import(/* webpackChunkName: "v2" */ "@/views/V2/SelectBanks.vue"),
      //   props: true,
      // },
      {
        path: "select-banks",
        name: "v2.select-banks",
        component: () =>
          import("@/views/V2/ConsentDecision/ConsentFlowDecision.vue"),
        props: false,
      },
      {
        path: "aa-login",
        name: "v2.aa-login",
        component: () =>
          import(/* webpackChunkName: "v2" */ "@/views/V2/AALoginScreen.vue"),
        props: true,
      },
      {
        path: "allow-consent",
        name: "v2.allow-consent",
        component: () =>
          import(/* webpackChunkName: "v2" */ "@/views/V2/ConsentPage.vue"),
        props: true,
      },
      {
        path: "discovered-accounts",
        name: "v2.discovered-accounts",
        component: () =>
          import(
            /* webpackChunkName: "v2" */ "@/views/V2/DiscoveredAccounts.vue"
          ),
        props: true,
      },
      {
        path: "aa-banks",
        name: "v2.aa-banks",
        component: () =>
          import(/* webpackChunkName: "v2" */ "@/views/V2/AABanks.vue"),
        props: true,
      },
      {
        path: "thank-you",
        name: "v2.thank-you",
        component: () =>
          import(/* webpackChunkName: "v2" */ "@/views/V2/ThankYou.vue"),
        props: true,
      },
      {
        path: "error",
        name: "v2.error",
        component: () =>
          import(/* webpackChunkName: "v2" */ "@/views/V2/ErrorPage.vue"),
        props: true,
      },
    ],
  },

  // V2 updated
  {
    path: "/v2u/:identifier",
    name: "v2u.landing",
    component: () =>
      import(
        /* webpackChunkName: "v2updated" */ "@/views/V2-Updated/V2Base.vue"
      ),
    props: true,
  },
  {
    path: "/v2n/:identifier",
    name: "v2n.landing",
    component: () =>
      import(
        /* webpackChunkName: "v2npdated" */ "@/views/v2n/V2Base.vue"
      ),
    props: true,
  },
  {
    path: "/mv2n/:identifier",
    name: "mv2n.landing",
    component: () =>
      import(
        /* webpackChunkName: "v2npdated" */ "@/views/v2n/V2Base.vue"
      ),
    props: true,
  },
  {
    path: "/mv2/:identifier",
    name: "mv2.landing",
    component: () =>
      import(
        /* webpackChunkName: "v2updated" */ "@/views/V2-Updated/V2Base.vue"
      ),
    props: true,
  },

  // V2X updated
  {
    path: "/xu/a",
    name: "v2X.landing",
    component: () =>
      import(
        /* webpackChunkName: "v2updated" */ "@/views/V2-Updated/V2Base.vue"
      ),
    props: false,
    /* children:[
    ] */
  },

  {
    path: "/x-error",
    name: "v2u.x-error",
    component: () =>
      import(
        /* webpackChunkName: "v2updated" */ "@/views/V2-Updated/XErrorPage.vue"
      ),
  },
  {
    path: "/error",
    name: "v2u.error",
    component: () =>
      import(
        /* webpackChunkName: "v2updated" */ "@/views/V2-Updated/ErrorPage.vue"
      ),
  },

  {
    path: "/x",
    name: "consent.landing",
    component: () => import("@/views/V2/ConsentDecision/BaseWrapper.vue"),
    children: [
      {
        path: "a",
        name: "consent.decision",
        component: () =>
          import("@/views/V2/ConsentDecision/ConsentFlowDecision.vue"),
        props: false,
      },

      // {
      //   path: "a",
      //   name: "v2.select-banks",
      //   component: () =>
      //     import(/* webpackChunkName: "v2" */ "@/views/V2/SelectBanks.vue"),
      //   props: false,
      // },

      {
        path: "a/:xid",
        name: "consent.progress",
        component: () =>
          import("@/views/V2/ConsentDecision/ConsentProgress.vue"),
        props: true,
      },
      {
        path: "x-error",
        name: "v2.x-error",
        component: () =>
          import(
            /* webpackChunkName: "v2" */ "@/views/V2/ConsentDecision/ErrorPage.vue"
          ),
      },
    ],
  },
  {
    path: "/:identifier/thank-you",
    name: "user.thank-you",
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/ThankYouView.vue"),
    props: true,
  },
  {
    path: "/error",
    name: "error",
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/ErrorView.vue"),
    props: true,
  },

  // ITR New Route
  {
    path: "/i",
    name: "itr.landing",
    redirect: "/i/:identifier",
    component: () =>
      import(/* webpackChunkName: "v2" */ "@/views/ITR/BaseView.vue"),
    props: true,
    children: [
      {
        path: ":identifier",
        name: "itr.identifier",
        component: () =>
          import(/* webpackChunkName: "itr" */ "@/views/ITR/BaseRoute.vue"),
        props: true,
        children: [
          {
            path: "",
            name: "itr.identifier.password",
            component: () =>
              import(
                /* webpackChunkName: "itr" */ "@/views/ITR/PasswordView.vue"
              ),
            props: true,
          },
          {
            path: "forgot-password",
            name: "itr.identifier.forgot-password",
            component: () =>
              import(
                /* webpackChunkName: "itr" */ "@/views/ITR/ForgotPasswordView.vue"
              ),
            props: true,
          },
          {
            path: "thank-you",
            name: "itr.identifier.thank-you",
            component: () =>
              import(
                /* webpackChunkName: "itr" */ "@/views/ITR/ThankYouView.vue"
              ),
            props: true,
          },
          {
            path: "expired",
            name: "itr.identifier.expired",
            component: () =>
              import(
                /* webpackChunkName: "itr" */ "@/views/ITR/ExpiredView.vue"
              ),
            props: true,
          },
          {
            path: "certError",
            name: "itr.identifier.cert.error",
            component: () =>
              import(/* webpackChunkName: "itr" */ "@/views/ErrorView.vue"),
            props: true,
          },
        ],
      },
    ],
  },
  // abdm
  {
    path: "/abdm/:identifier",
    name: "abdm.landing",
    component: () =>
      import(/* webpackChunkName: "abdm" */ "@/views/abdm/BaseView.vue"),
    props: true,
  },
  // V6
  {
    path: "/v6/:identifier",
    name: "V6.landing",
    component: () =>
      import(/* webpackChunkName: "stockal" */ "@/views/V6/V6Base.vue"),
    props: true,
  },

  // V5
  {
    path: "/fivepaisa/:identifier",
    name: "fivepaisa.landing",
    component: () =>
      import(
        /* webpackChunkName: "fivePaisa" */ "@/views/fivepaisa/FivePaisaBase.vue"
      ),
    redirect: "/fivepaisa/segment-details",
    props: true,
    children: [
      {
        path: "segment-details",
        name: "fivepaisa.segment-details",
        component: () => import("@/views/fivepaisa/SegmentDetails.vue"),
        props: false,
      },
      {
        path: "user-verifcation",
        name: "fivepaisa.user-verifcation",
        component: () => import("@/views/fivepaisa/UserVerification.vue"),
        props: false,
      },
      {
        path: "link-account",
        name: "fivepaisa.link-bank-account",
        component: () => import("@/views/fivepaisa/LinkBankAccount.vue"),
        props: false,
      },
      {
        path: "add-registered-mobile",
        name: "fivepaisa.add-registered-mobile",
        component: () => import("@/views/fivepaisa/AddMobileNumber.vue"),
        props: false,
      },
      {
        path: "select-banks",
        name: "fivepaisa.select-banks",
        component: () => import("@/views/fivepaisa/SelectBanks.vue"),
        props: false,
      },
      {
        path: "thank-you",
        name: "fivepaisa.thank-you",
        component: () => import("@/views/fivepaisa/ThankYou.vue"),
        props: false,
      },
      // {
      //   path: "bank-search",
      //   name: "fivepaisa.bank-search",
      //   component: () =>
      //     import("@/views/five-paisa/BankSearch.vue"),
      //   props: false,
      // },
      // {
      //   path: "account-discovery",
      //   name: "fivepaisa.account-discovery",
      //   component: () =>
      //     import("@/views/five-paisa/AccountDiscovery.vue"),
      //   props: false,
      // },
    ],
  },

  // ABCD
  {
    path: "/abcd/:identifier",
    name: "abcd.landing",
    component: () =>
      import(/* webpackChunkName: "abcd" */ "@/views/abcd/BaseView.vue"),
    props: true,
  },
  // Max Life Insurance
  {
    path: "/mli/:identifier",
    name: "mli.landing",
    component: () =>
      import(/* webpackChunkName: "mli" */ "@/views/mli/BaseView.vue"),
    props: true,
  },
  // DSP
  {
    path: "/dsp/:identifier",
    name: "dsp.landing",
    component: () =>
      import(/* webpackChunkName: "dsp" */ "@/views/dsp/BaseView.vue"),
    props: true,
  },
  {
    path: "/v3/:identifier",
    name: "v3.landing",
    component: () =>
      import(/* webpackChunkName: "v3" */ "@/views/navi/NaviBase.vue"),
    props: true,
  },
  {
    // navi
    path: "/navi/:identifier",
    name: "navi.landing",
    component: () =>
      import(/* webpackChunkName: "v3" */ "@/views/navi/NaviBase.vue"),
    props: true,
  },
  {
    path: "/mv3/:identifier",
    name: "mv3.landing",
    component: () =>
      import(/* webpackChunkName: "v3" */ "@/views/navi/NaviBase.vue"),
    props: true,
  },
  {
    path: "/balic/:identifier",
    name: "balic.landing",
    component: () =>
      import(/* webpackChunkName: "balic" */ "@/views/balic/views/BaseView.vue"),
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;