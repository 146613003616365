// FIXME: Add proper types instead of generic Function and {}
/* eslint-disable @typescript-eslint/ban-types */
import useUtilService from "@/services/util.service";
import { LinkedAccount } from "@finarkein/aasdk-core";
import Fuse from "fuse.js";
import { ComputedRef, Ref, computed, reactive, ref } from "vue";
import { useAAJourneyStore } from "@/store/aa-journey.store";
import useConductor from "./conductor";
import { JourneyType } from "./journey-constants";
const { humanizeAccountType } = useUtilService();

export type AaMeta = {
  handle: string;
  id: string;
  logo?: string;
  version?: string;
} & Record<string, any>;

export type Customer = {
  maskedMobile: string;
  mobile?: string;
  maskedHandle?: string;
  handle?: string;
  userId?: string;
};

export type ConsentRequestDetails = {
  consentHandle: string;
  redirectUrl?: string;
  webviewBaseUrl?: string;
  txnId?: string;
  parameters?: {
    fi: string;
    ecreq: string;
    reqdate: string;
  };
} & Record<string, any>;

export type EncrypedParams = {
  encryptedRequest?: string;
  requestDate?: string;
  encryptedFiuId?: string;
};

export type linkAccProto = {
  // userId: string,
  fipId: string;
  fipName: string;
  maskedAccNumber: string;
  accRefNumber: string;
  linkRefNumber: string;
  // consentIdList: [],
  FIType: string;
  accType: string;
  // linkedAccountUpdateTimestamp: string,
  // AuthenticatorType: string,
};

export type ConsentResponse = {
  status: string;
  message: null;
  consentId: null;
  ver: string;
  txnid: string;
  consentStatus: any;
  createTime: string;
  startTime: string;
  expireTime: string;
  statusLastupdateTimestamp: string;
  FIP: any;
  AA: {};
  FIU: {
    id?: string;
    name?: string;
  };
  User: {};
  Accounts: any;
  ConsentUse: any;
  DataAccess: any;
  Purpose: {};
  Signature: string;
  mode: string;
  fetchType: string;
  consentTypes: [];
  consentDisplayDescriptions: [];
  fiTypes: [];
  DataDateTimeRange: {};
  DataLife: {};
  Frequency: {};
  DataFilter: [];
  consentDetailDigitalSignature: any;
  ConsentHandle: string;
};

export type FIPConsentInfoResponseProto = {
  fipConsentInfos: {
    fipId: string;
    consentId: string;
  }[];
  status: string;
  message: string;
};

export type discoveredAccountProto = {
  accType: string;
  accRefNumber: string;
  maskedAccNumber: string;
  FIType: string;
};

export type AccountLinkingResponseProto = {
  status: string;
  message: string;
  ver: string;
  timestamp: string;
  txnid: string;
  AuthenticatorType: string;
  RefNumber: string;
};

export type AccountLinkedResProto = {
  status: string;
  message: string;
  ver: string;
  timestamp: string;
  txnid: string;
  AccLinkDetails: [];
};

export type MarkCompleteDetails = {
  decryptedValues: {
    errorCode: number;
    fi: string;
    redirectUrl: string;
    sessionId: string;
    srcRef: string;
    status: string;
    txnId: string;
    userId: string;
  };
  updateStatus: boolean;
};

export type UserInfo = {
  userId: string;
  mobileNo: string;
  mobileAuthenticated: string;
  emailId: string;
  emailAuthenticated: string;
};

export type Institution = {
  id: string;
  logo?: string;
  version: string;
  name: string;
  fiTypes: Array<string>;
  aa: Array<string>;
  identifiers?: Array<any>;
  extraConfig?: {
    hint?: number;
    otpLength?: number;
    otpDigitsOnly?: boolean;
  };
};

export class FinancialAccount {
  constructor(
    public maskedAccNumber: string,
    public accRefNumber: string,
    public FIType: string,
    public accType: string,
    public fipId: string,
    public fipName: string,
    public identifierSeq: number = 0,
    public linkRefNumber?: string,
    // added new key TODO: it should be here??
    public id?: string,
  ) {}

  public isLinked(): boolean {
    return this.linkRefNumber !== undefined || this.linkRefNumber === "";
  }

  get type() {
    return humanizeAccountType(this.FIType, this.accType);
  }

  public shapedForLinking() {
    return {
      maskedAccNumber: this.maskedAccNumber,
      accRefNumber: this.accRefNumber,
      FIType: this.FIType,
      accType: this.accType,
      id: this.id, // make it according to the aahandle
    };
  }
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type UserLinkedAccount = LinkedAccount & {
  identifierSeq: number;
};

export type DiscoveredAccount = Omit<
  UserLinkedAccount,
  "linkRefNumber" | "fipId" | "fipName"
>;

export enum DiscoveryStatus {
  undefined,
  SUCCESS,
  FAILED,
  NO_ACCOUNTS,
}

export type InstitutionDiscovery = {
  discovering: boolean; // is discovery underway for the institution at a given moment
  status: DiscoveryStatus;
  lastResult: FinancialAccount[];
  auto: boolean;
};

export type InstitutionDiscoveryUpdate = Omit<
  InstitutionDiscovery,
  "lastResult"
>;

type AccountKeyRequiredFields = Pick<FinancialAccount, "maskedAccNumber" | "FIType" | "accType">;

export class InstitutionAccounts {
  public discovery: InstitutionDiscovery;
  public _accKeyToAccRef = reactive(new Map());
  public accounts: Map<string, FinancialAccount> = reactive(new Map());
  public selections: FinancialAccount[] = reactive([]);

  constructor(
    public readonly meta: Institution,
    public readonly stable: boolean = true,
    auto = false,
  ) {
    this.discovery = reactive({
      discovering: false,
      status: DiscoveryStatus.undefined,
      lastResult: [],
      auto: auto,
    });
  }

  private _doLinkedAccount(
    account: UserLinkedAccount,
    store: Map<string, FinancialAccount>,
    autoSelect: boolean,
    accountTypeFilter: string,
  ) {
    const accRefNumber = account.accRefNumber;

    let target = store.get(accRefNumber);
    const accountKey = this._getAccountKey(account);
    this._accKeyToAccRef.set(accountKey, accRefNumber);
    // check, initialize & update if required
    if (target == null || target == undefined) {
      // target = new FinancialAccount(mask, accRefNumber, account.FIType, account.accType, this.meta.id, this.meta.name,0);
      target = new FinancialAccount(
        account.maskedAccNumber,
        accRefNumber,
        account.FIType,
        account.accType,
        this.meta.id,
        this.meta.name,
        0,
        undefined,
        account.id,
      );

      store.set(accRefNumber, target);
    }
    target.identifierSeq = account.identifierSeq; // Record identifie sequence number
    target.id = account.id; // update id if required
    if (account.linkRefNumber) {
      // this account is now linked
      target.linkRefNumber = account.linkRefNumber;
    }
    // if account type filter is present remove the rest of the account which are not required
    if (accountTypeFilter && accountTypeFilter.length !== 0) {
      // check if its present in account type filters
      if (!accountTypeFilter.includes(target.accType)) {
        store.delete(target.accRefNumber);
        target = undefined;
      }
    }
    if (autoSelect && target) {
      this.selections.push(target);
      this._refreshSelections();
    }
  }

  public addOrUpdateLinkedAccount(
    account: UserLinkedAccount,
    autoSelect: boolean,
    accountTypeFilter: string,
  ) {
    this._doLinkedAccount(
      account,
      this.accounts,
      autoSelect,
      accountTypeFilter,
    );
  }

  public linkPreviouslyDiscoveredAccounts(
    updates: {
      customerAddress?: string;
      linkRefNumber: string;
      accRefNumber: string;
      status: string | "LINKED";
    }[],
    autoSelect: boolean,
    accountTypeFilter: string,
  ) {
    // Collect using accRefNumber from the last discovered accounts
    updates.forEach((u) => {
      const found = this.discovery.lastResult?.find(
        a => u.accRefNumber === a.accRefNumber,
      );
      if (found) {
        // Set the linkRefNumber
        found.linkRefNumber = u.linkRefNumber;
        this.addOrUpdateLinkedAccount(
          found as UserLinkedAccount,
          autoSelect,
          accountTypeFilter,
        );
        this._refreshSelections();
      } else {
        console.error(
          "An undiscovered account was tried to be marked linked "
          + u.accRefNumber,
        );
      }
    });
  }

  private _refreshSelections() {
    const prevSelections = new Set(
      this.selections.map(data => data.accRefNumber),
    );
    const newSelections = [] as FinancialAccount[];
    this.allAccounts().value.forEach((data) => {
      if (prevSelections.has(data.accRefNumber)) {
        newSelections.push(data);
      }
    });
    this.selections = newSelections;
  }

  private _objToString<T>(obj: T, fields: Array<keyof T>, prefixes: string[] = []) {
    const fieldValues = fields.map(f => obj[f] || "");
    const resultArray = [...prefixes, ...fieldValues];
    return resultArray.join("_");
  }

  private _getAccountKey<T extends AccountKeyRequiredFields>(account: T) {
    return this._objToString(account, ["maskedAccNumber", "FIType", "accType"]);
  }

  public setDiscoveredAccounts(
    accounts: DiscoveredAccount[],
    idSeq: number,
    autoSelect: boolean,
    accountTypeFilter = "",
  ) {
    // set lastresult as array for 1st time discovery
    if (!Array.isArray(this.discovery.lastResult)) {
      this.discovery.lastResult = [];
    }

    /* Create a Set to track unique account identifiers (here we use identifierSeq(represents mobile number) + id(unique identifer) + maskedAccNumber + accRefNumber + fitype) to uniquely identify the account. This is created to avoid duplicates; if the same account is discovered multiple times for that given fip */
    const _fieldsForUniqueAccounts = ["id", "maskedAccNumber", "accRefNumber"] as Array<keyof FinancialAccount>;
    const uniqueAccounts = new Set(
      this.discovery.lastResult
        .map(account => this._objToString(account, _fieldsForUniqueAccounts, [account.identifierSeq.toString()])),
    );

    // Process new accounts
    const newAccounts = accounts.reduce((result, current) => {
      const _daFields = _fieldsForUniqueAccounts as Array<keyof DiscoveredAccount>;
      // Format: `${idSeq}_${current.id}_${current.maskedAccNumber}_${current.accRefNumber}_${current.FIType}`
      const accountKey = this._objToString(current, _daFields, [idSeq.toString()]);

      if (!uniqueAccounts.has(accountKey)) {
        uniqueAccounts.add(accountKey);
        result.push(new FinancialAccount(
          current.maskedAccNumber,
          current.accRefNumber,
          current.FIType,
          current.accType,
          this.meta.id,
          this.meta.name,
          idSeq,
          undefined,
          current.id,
        ));
      }

      return result;
    }, [] as FinancialAccount[]);

    // Append new unique accounts to the existing result
    this.discovery.lastResult = this.discovery.lastResult.concat(newAccounts);
    if (accountTypeFilter && accountTypeFilter.length !== 0) {
      const filteredArray = this.discovery.lastResult
        .slice()
        .filter(da => accountTypeFilter.includes(da.accType));
      this.discovery.lastResult = filteredArray;
    }
    if (autoSelect) {
      // autoSelect mode on, reselect all linked accounts again - just in case
      for (const a of this.accounts.values()) {
        this.selections.push(a);
      }
      // update the selection list with all newly discovered accounts
      this.discovery.lastResult.forEach(a => this.selections.push(a));
      // force dedup of selections array
      this._refreshSelections();
    }
  }

  public updateDiscoveryStatus(ds: Partial<InstitutionDiscoveryUpdate>) {
    this.discovery = {
      ...this.discovery,
      ...ds,
    };
  }

  public allAccounts(searchQuery?: string) {
    let result: FinancialAccount[] = [];
    // const linked: FinancialAccount[] = [];
    // const discovered: FinancialAccount[] = [];

    for (const value of this.accounts.values()) {
      result.push(value); // to render linked accounts only
    }

    if (this.discovery?.lastResult) {
      // if discovery result is available
      this.discovery.lastResult.forEach((value) => {
        const accountKey = this._getAccountKey(value);
        if (this._accKeyToAccRef.has(accountKey)) {
          return;
        }
        result.push(value); // uncomment and show discovered acc also
      });
    }

    if (searchQuery) {
      const fuse = new Fuse(result, {
        keys: ["fipName", "maskedAccNumber", "type"],
      });
      result = fuse.search(searchQuery).map(m => m.item);
    }

    return ref(result);
  }

  public loading() {
    return computed(() => this.discovery.discovering);
  }

  public noAccounts() {
    return computed(() => {
      if (this.loading().value) return true;

      // else check for existence of any accounts
      return this.allAccounts().value.length === 0;
    });
  }

  public getDiscoveredAccStatus() {
    return this.discovery.status;
  }

  public isAutoDiscovery() {
    return computed(() => this.discovery.auto);
  }

  public getLinkedAccounts() {
    const result: FinancialAccount[] = [];
    for (const value of this.accounts.values()) {
      result.push(value); // to render linked accounts only
    }
    return ref(result);
  }

  public getDiscoveredAccounts() {
    const result: FinancialAccount[] = [];
    if (this.discovery?.lastResult) {
      // if discovery result is available
      this.discovery.lastResult.forEach((value) => {
        const accountKey = this._getAccountKey(value);
        if (this._accKeyToAccRef.has(accountKey)) {
          return;
        }
        result.push(value); // uncomment and show discovered acc also
      });
    }
    return ref(result);
  }
}

export type ConsentRequestInfo = ConsentResponse;
export type CustomerParams = {
  encryptedFiuId: string;
  encryptedRequest: string;
  requestDate: string;
};
export type AutoDiscover = {
  loading?: boolean;
  title: string;
  description: string;
  mobileNumber?: string;
  btnStyle?: string;
} & Record<string, any>;
// journey types

export type ConductorType = {
  missingAcc: ComputedRef<boolean>;
  bankFound: Ref<any>;
  anythingLoading: Ref<any>;
  viewHandler: Ref<any>;
  init: Function;
  ensureNext: Function;
  cancelAndExit: Function;
  exitTheWorld: Function;
  handleSessionError: Function;
  handleBadRequest: Function;
  handleAPIError: Function;

  // AA specific stuff
  resendAaOTP: Function;
  verifyAaOTPAndNext: Function;
  handleConsentAction: Function;
  doAccountDiscovery: Function;
  _initForAlternateMobile: Function;
  _confirmAlternateMobile: Function;
  initiateLinking: Function;
  confirmLinking: Function;
  addMoreBankAccounts: Function;

  // exporting the components
  // components: views,
  // getInstitutionListForDisplay
  discoverAccountsFromInstitutions: Function;
  navigateBack: Function;
  resendMobileAuthOTP: Function;

  // events
  fireJourneyEvents: Function;
  getAABasedOnFip: Function;
  handleFailures: Function;
  switchAA: Function;
  autoDiscovery: Function;
  collectTopPrefFips: Function;
  transitionToView: Function;

  // for disabling back functionality
  disableBackOnLoad: Function;
  disableOnPopState: Function;
  onLoad: Function;
  onPopState: Function;
  removeEventListeners: Function;

  // feature resolvers
  setJourneyFeatures: Function;
  [key: string]: any;
};
export type BrandInfo = {
  color: string;
  logo: string;
  name: string;
};
export type AAProto = {
  handle: string;
  id: string;
  logo?: string | undefined;
  version?: string | undefined;
} & Record<string, string>;

export type JourneyTemplate = {
  login_pageTitle?: string;
  login_pageSubTitle?: string;
  login_otpTitle?: string;
  login_otpSubHeading?: string;
  login_otpSubHeadingShowChangeNumber?: boolean;
  login_primaryCTA?: string;
  login_secondaryCTA?: string;
  login_note_dataStyle?: string;
  login_note_headingStyle?: string;
  selectFips_searchText?: string;
  selectFips_primaryCTA?: string;
  selectFips_noAccountsImg?: string;
  selectFips_secondaryCTA?: string;
  selectFips_showActions?: string;
  accounts_pageTitle?: string;
  accounts_pageSubtitle?: string;
  accounts_primaryCTA?: string;
  accounts_secondaryCTA?: string;
  accounts_addBank?: boolean;
  accounts_enableAddBank?: boolean;
  accounts_bankList_title?: string;
  accounts_showAddNumber?: boolean;
  accounts_showIcon?: boolean;
  altMobile_title?: string;
  altMobile_titleShowIcon?: string;
  altMobile_subTitle?: string;
  altMobile_inputTitle?: string;
  altMobile_primaryCTA?: string;
  altMobile_secondaryCTA?: string;
  flpLinking_primaryCTA?: string;
  flpLinking_secondaryCTA?: string;
  flpLinking_errorText?: string;
  bankOTP_title?: string;
  bankOTP_subTitle?: string;
  bankOTP_primaryCTA?: string;
  bankOTP_secondaryCTA?: string;
  bankOTP_showLinkingCount?: boolean;
  unableOtpDialog_title?: string;
  unableOtpDialog_subTitle?: string;
  unableOtpDialog_primaryCTA?: string;
  unableOtpDialog_secondaryCTA?: string;
  addMobileDialog_title?: string;
  addMobileDialog_subTitle?: string;
  addMobileDialog_primaryCTA?: string;
  addMobileDialog_secondaryCTA?: string;
  addMobileDialog_titleShowIcon?: string;
  errorDialog_title?: string;
  errorDialog_subTitle?: string;
  errorDialog_primaryCTA?: string;
  errorDialog_secondaryCTA?: string;
  noAccounts_title?: string;
  noAccounts_subTitle?: string;
  noAccounts_primaryCTA?: string;
  noAccounts_secondaryCTA?: string;
} & Record<string, any>;

export type JourneyFeaturesLayoutModel = {
  fontFamily?: string;
  fontFamilyURL?: string;
  fontColor?: string;
  ctaDesign?: string;
  primaryColor?: string;
  bodyBackGroundColor?: string;
  secondaryColor?: string;
  loaderColor?: string;
  selectionColor?: string;
  input_rounded?: string;
  input_active?: string;
  input_disable?: string;
  otpInputType?: string;
  otpSplitCount?: string;
};

export type FileInfo = {
  file: File;
  fileName: string;
  bankName: string;
  passwordStatus: boolean;
  fileUploadStatus: string;
  password?: string;
  fileError?: string;
};
/* export type JourneyStore = {
    brand:Ref<BrandInfo>,
    isProcessing:Ref<boolean>,
    exitWorld:Ref<boolean>,
    aaAuth:Ref<boolean>,

    requestId: Ref<string>,
    tenantId: Ref<string>,
    v3Title:Ref<string>,
    customer:Ref<Customer>,
    customerMobile: ComputedRef<string>,
    filterParser:Ref<AccountFilterParser | undefined>,
    lastKnownStatus:Ref<string>,
    viewHandler: ShallowRef<any>
    aaSdkAdapte:Ref<any>
    aaHandle:Ref<string>,
    aaSdk:Ref<AaWebSdkController | undefined>,
    availableAAs: Ref<{

    }[]>,

    encryptedParams:Ref<CustomerParams>,
    consentHandle: Ref<string>,
    consentRequestInfo:Ref<ConsentResponse | undefined>,
    consentDetail:any,
    financialInstruments:ComputedRef<string[]>
    consentAction:any,
    otpReference:Ref<string | undefined>
    awaitNext: Ref<Promise<void> | undefined>,
    institutionWiseAccounts:Ref<Map<string, InstitutionAccounts>>,
    additionalMobiles:Ref<string[]>,
    totalAccountsCount: ComputedRef<number>
    // some discovery metadata
    discoveryQueue: Ref<string[]>,
    autoDiscoveryCount: Ref<number>,
    removeListener: ComputedRef<0 | 1>,
    consentTemplate: { id: string, def: any },

    updateFromRequestDetails:Function,
    loadInstitutions: Promise<void>,
    setSelectedInstitutions:void,
    getInstitutions:Function,
    updateWebViewParams: Function,
    updateConsentHandle:Function,
    updateLinkedAccounts:Function,
    updateInstitutionDiscoveryQueue:Function,
    updateDiscoveredAccounts:Function,
    linkDiscoveredAccounts:Function,
    updateDiscoveryStatus:Function,
    updateUserInformation:Function,
    updateInstitutions:Function,
    updateConsentRequestInfo:Function,
    updateConsenTemplate:Function,

    troubleshootError:Ref<any>,
    $reset: Function,
    otpForUser:Ref<Map<string, any>>,
    currentMobile: Ref<string>,

    // FEATURES specific
    getFeature:Function,
    setFeature: Function,

    workingInstitutions:  ComputedRef<Map<string, InstitutionAccounts>>,
    anythingLoading:  ComputedRef<boolean>,
    selectedSet:any,
    getAALogo:ComputedRef<DefineComponent>,
    bankFound:  ComputedRef<boolean>,
    totalAccounts:  ComputedRef<number>
    selectedAccountsCount: ComputedRef<number>,
    noAccountSelected: ComputedRef<boolean>,
    isAutoDiscoveryInProgress: ComputedRef<boolean>,
    features: Ref<Map<string, any>>,
    [key:string]:any
} */
export type JourneyStoreType = ReturnType<typeof useAAJourneyStore>;
export type JourneyConductorType = ReturnType<typeof useConductor>;
/* type ViewLayout =  {
    name:String,
    comp:any
};
export type JourneyViewModel = {
    discovery:Record<string,ViewLayout>,
    [key:string]: Record<string,ViewLayout>;
};
 */
export type IdentifiersProto = {
  category: string;
  type: string;
  value: any;
} & Record<string, any>;

export const ListOfIdentifiers = [
  // {
  //   type: "MOBILE",
  // },
  {
    type: "DOB",
  },
  {
    type: "EMAIL",
  },
  {
    type: "PAN",
  },
];

export type LinkJob = {
  id: string;
  toLink: FinancialAccount[];
  skip: boolean;
  complete: boolean;
  raw: InstitutionAccounts;
  loading: boolean;
  temp?: AccountLinkingResponseProto;
  redo?: Function;
};

export type JourneyTypeResolver = {
  jtype: JourneyType;
} & AuthParams & Record<string, any>;
type AuthParams = {
  receivedAuth: string;
  refreshToken: string;
};

export type UserConsentedAccount = {
  customerAaId: string;
  maskedAccNumber: string;
  fiType: string;
  fipId: string;
  accType: string;
  createdOn: string;
};